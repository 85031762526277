import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updatePassword";
export const UPDATE_USER = "updateUser";
export const GET_USER = "profile";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_TOKEN = "setToken";
export const SET_PROFILE = "setProfile";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
	errors: [],
	user: {},
	isAuthenticated: !!JwtService.getToken()
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	}
};

const actions = {
	[LOGIN](context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post("client/login", credentials)
				.then(({ data }) => {
					if (data.code == 200) {
						context.commit(SET_TOKEN, data.data.access_token);
					} else if (data.code == 400) {
						let errors = Object.values(data.errors)
							.flat()
							.map(function(item) {
								return item;
							});
						context.commit(SET_ERROR, errors);
					}
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, [response.data.message]);
					reject(response);
				});
		});
	},
	[GET_USER](context) {
		if (JwtService.getToken()) {
			ApiService.setHeader();
			return new Promise((resolve, reject) => {
				ApiService.get("client/profile")
					.then(({ data }) => {
						context.commit(SET_PROFILE, data.data);
						resolve(data);
					})
					.catch(({ response }) => {
						if (response.status == 401) {
							context.commit(PURGE_AUTH);
						} else {
							context.commit(SET_ERROR, response.data.message);
						}
						reject(response);
					});
			});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[UPDATE_USER](context, payload) {
		if (JwtService.getToken()) {
			ApiService.setHeader();
			return new Promise((resolve, reject) => {
				ApiService.post("client/profile", payload)
					.then(({ data }) => {
						context.commit(SET_PROFILE, data.data);
						resolve(data);
					})
					.catch(({ response }) => {
						// if (response.status == 401) {
						// 	context.commit(PURGE_AUTH);
						// } else {
						// 	context.commit(SET_ERROR, response.data.message);
						// }
						reject(response);
					});
			});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
	[REGISTER](context, credentials) {
		return new Promise(resolve => {
			ApiService.post("client/register", credentials)
				.then(({ data }) => {
					if (data.code == 400) {
						let errors = Object.values(data.errors)
							.flat()
							.map(function(item) {
								return item;
							});
						context.commit(SET_ERROR, errors);
					}
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[VERIFY_AUTH](context) {
		if (JwtService.getToken()) {
			ApiService.setHeader();
			ApiService.get("client/check-token")
				.then(({ data }) => {
					context.commit(SET_AUTH, data);
				})
				.catch(() => {
					context.commit(PURGE_AUTH);
				});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[UPDATE_PASSWORD](context, payload) {
		const password = payload;

		return ApiService.put("password", password).then(({ data }) => {
			context.commit(SET_PASSWORD, data);
			return data;
		});
	}
};

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTH](state) {
		state.isAuthenticated = true;
		state.errors = [];
	},
	[SET_TOKEN](state, token) {
		state.isAuthenticated = true;
		state.errors = [];
		JwtService.saveToken(token);
	},
	[SET_PROFILE](state, user) {
		state.user = user;
	},
	[SET_PASSWORD](state, password) {
		state.user.password = password;
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.errors = [];
		JwtService.destroyToken();
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
