// France
export const locale = {
	TRANSLATOR: {
		SELECT: "choisissez votre langue"
	},
	MENU: {
		NEW: "Nouveau",
		ACTIONS: "Actes",
		CREATE_POST: "Créer un nouveau Post",
		PAGES: "Pages",
		FEATURES: "Fonctionnalités",
		APPS: "Applications",
		DASHBOARD: "Tableau de Bord"
	},
	AUTH: {
		GENERAL: {
			OR: "Ou",
			SUBMIT_BUTTON: "Soumettre",
			NO_ACCOUNT: "Ne pas avoir de compte?",
			SIGNUP_BUTTON: "Registre",
			FORGOT_BUTTON: "Mot de passe oublié",
			BACK_BUTTON: "Back",
			PRIVACY: "Privacy",
			LEGAL: "Legal",
			CONTACT: "Contact"
		},
		LOGIN: {
			TITLE: "Créer un compte",
			BUTTON: "Sign In"
		},
		FORGOT: {
			TITLE: "Forgot Password?",
			DESC: "Enter your email to reset your password",
			SUCCESS: "Your account has been successfully reset."
		},
		REGISTER: {
			TITLE: "Sign Up",
			DESC: "Enter your details to create your account",
			SUCCESS: "Your account has been successfuly registered."
		},
		INPUT: {
			EMAIL: "Email",
			FULLNAME: "Fullname",
			PASSWORD: "Mot de passe",
			CONFIRM_PASSWORD: "Confirm Password",
			USERNAME: "Nom d'utilisateur"
		},
		VALIDATION: {
			INVALID: "{{name}} n'est pas valide",
			REQUIRED: "{{name}} est requis",
			MIN_LENGTH: "{{name}} minimum length is {{min}}",
			AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
			NOT_FOUND: "The requested {{name}} is not found",
			INVALID_LOGIN: "The login detail is incorrect",
			REQUIRED_FIELD: "Required field",
			MIN_LENGTH_FIELD: "Minimum field length:",
			MAX_LENGTH_FIELD: "Maximum field length:",
			INVALID_FIELD: "Field is not valid"
		}
	},
	ECOMMERCE: {
		COMMON: {
			SELECTED_RECORDS_COUNT: "Nombre d'enregistrements sélectionnés: ",
			ALL: "All",
			SUSPENDED: "Suspended",
			ACTIVE: "Active",
			FILTER: "Filter",
			BY_STATUS: "by Status",
			BY_TYPE: "by Type",
			BUSINESS: "Business",
			INDIVIDUAL: "Individual",
			SEARCH: "Search",
			IN_ALL_FIELDS: "in all fields"
		},
		ECOMMERCE: "éCommerce",
		CUSTOMERS: {
			CUSTOMERS: "Les clients",
			CUSTOMERS_LIST: "Liste des clients",
			NEW_CUSTOMER: "Nouveau client",
			DELETE_CUSTOMER_SIMPLE: {
				TITLE: "Suppression du client",
				DESCRIPTION:
					"Êtes-vous sûr de supprimer définitivement ce client?",
				WAIT_DESCRIPTION: "Le client est en train de supprimer ...",
				MESSAGE: "Le client a été supprimé"
			},
			DELETE_CUSTOMER_MULTY: {
				TITLE: "Supprimer les clients",
				DESCRIPTION:
					"Êtes-vous sûr de supprimer définitivement les clients sélectionnés?",
				WAIT_DESCRIPTION: "Les clients suppriment ...",
				MESSAGE: "Les clients sélectionnés ont été supprimés"
			},
			UPDATE_STATUS: {
				TITLE:
					"Le statut a été mis à jour pour les clients sélectionnés",
				MESSAGE:
					"Le statut des clients sélectionnés a été mis à jour avec succès"
			},
			EDIT: {
				UPDATE_MESSAGE: "Le client a été mis à jour",
				ADD_MESSAGE: "Le client a été créé"
			}
		}
	}
};
