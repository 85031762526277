import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		// Vue.axios.defaults.baseURL = "http://market-place.test/api/";
		Vue.axios.defaults.baseURL = "https://working.legal.8gatestechtesting.com/api/";
	},

	/**
	 * Set the default HTTP request headers
	 */
	setHeader() {
		Vue.axios.defaults.headers.common[
			"Authorization"
		] = `Bearer ${JwtService.getToken()}`;
	},

	query(resource, params) {
		return Vue.axios.get(resource, params).catch(error => {
			// console.log(error);
			throw new Error(`[KT] ApiService ${error}`);
		});
	},

	/**
	 * Send the GET HTTP request
	 * @param resource
	 * @param slug
	 * @returns {*}
	 */
	get(resource) {
		return Vue.axios.get(`${resource}`);
	},

	/**
	 * Set the POST HTTP request
	 * @param resource
	 * @param params
	 * @returns {*}
	 */
	post(resource, params) {
		return Vue.axios.post(`${resource}`, params);
	},

	/**
	 * Send the UPDATE HTTP request
	 * @param resource
	 * @param slug
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	update(resource, params) {
		return Vue.axios.put(`${resource}`, params);
	},

	/**
	 * Send the PUT HTTP request
	 * @param resource
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	put(resource, params) {
		return Vue.axios.put(`${resource}`, params);
	},

	/**
	 * Send the DELETE HTTP request
	 * @param resource
	 * @returns {*}
	 */
	delete(resource) {
		return Vue.axios.delete(resource);
	}
};

export default ApiService;
