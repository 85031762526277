import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/dashboard",
			component: () => import("@/view/layout/Layout"),
			children: [
				{
					path: "/dashboard",
					name: "dashboard",
					component: () => import("@/view/pages/Dashboard.vue")
				},
				{
					path: "/profile",
					name: "profile",
					component: () => import("@/view/pages/profile/Profile.vue"),
					children: [
						{
							path: "info",
							name: "info",
							component: () =>
								import("@/view/pages/profile/Info.vue")
						}
					]
				}
			]
		},
		{
			path: "/",
			component: () => import("@/view/layout/Auth"),
			children: [
				{
					name: "login",
					path: "/login",
					component: () =>
						import("@/view/pages/auth/login_pages/Login-1")
				},
				{
					name: "register",
					path: "/register",
					component: () =>
						import("@/view/pages/auth/login_pages/Login-1")
				}
			]
		},
		{
			name: "verify-mail",
			path: "/verify-mail",
			component: () => import("@/view/pages/auth/VerifyMail")
		},
		{
			path: "*",
			redirect: "/404"
		},
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/view/pages/error/Error-1.vue")
		}
	]
});
